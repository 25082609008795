import React, { useContext } from "react";
import { PageProps, PageThemeConfig } from "types";
import { Nav } from "components/nav";
import { ThemeContext } from "context/theme";
import { Page } from "components/page";
import { DeepRequired } from "ts-essentials";

const themeConfig: DeepRequired<PageThemeConfig> = {
  dark: {
    background: "bg-gray-900",
    textOnBackground: "text-white",
  },
  light: {
    background: "bg-white",
    textOnBackground: "text-gray-900",
  },
};

const DAY_VIDEO_URL =
  process.env.NODE_ENV === "development"
    ? "/videos/acadia_day_1.mp4"
    : "https://res.cloudinary.com/michael-hueter/video/upload/v1580608050/michaelhueter/acadia_day_bc1y2n.mp4";
const NIGHT_VIDEO_URL =
  process.env.NODE_ENV === "development"
    ? "/videos/acadia_night_2.mp4"
    : "https://res.cloudinary.com/michael-hueter/video/upload/v1580784968/michaelhueter/acadia_night_2_krko6t.mp4";

const Homepage: React.FC<PageProps> = () => {
  const { theme } = useContext(ThemeContext);

  const videoPoster =
    theme === "dark"
      ? "/acadia_night_compressed.jpg"
      : "/acadia_day_compressed.jpg";

  const videoUrl = theme === "dark" ? NIGHT_VIDEO_URL : DAY_VIDEO_URL;

  return (
    <Page
      title="Home"
      description="Michael Hueter’s Website"
      themeConfig={themeConfig}
      className="overflow-hidden"
    >
      <Nav />
      <video
        autoPlay
        loop
        muted
        playsInline
        className="main-video"
        key={videoUrl}
        poster={videoPoster}
      >
        <source src={videoUrl} type="video/mp4" key={videoUrl} />
      </video>
    </Page>
  );
};

export default Homepage;
